/* eslint-disable */
import { createContext, useState, useEffect, useReducer } from 'react'

import { useNavigate } from 'react-router-dom';

import KirvisAPI from '@ourodemi/kirvis-js';
import IDaaSModule from '@ourodemi/idaas-js';

import { IDENTITY_API, LOCAL_SERVER, REMOTE_SERVER, DEV_MODE } from 'config';

import { UPDATE_USER } from 'store/actions';

import identityReducer, { initialState } from 'store/identityReducer';

const IdentityContext = createContext(null);

const IdentityAPI = new IDaaSModule(IDENTITY_API);
const Kirvis = new KirvisAPI(
    DEV_MODE ? LOCAL_SERVER : REMOTE_SERVER,
    { protocol: DEV_MODE ? 'http' : 'https' }
);

export const IdentityProvider = ({ children }) => {

    const [state, dispatch] = useReducer(identityReducer, initialState);

    const [isAuthenticated, setAuthenticated] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        IdentityAPI.isAuthenticated().then(setAuthenticated);
    }, []);

    useEffect(() => {
        if ( !isAuthenticated ){
            return;
        }
        
        IdentityAPI.getUser().then((user) => {
            if ( user ){
                dispatch({
                    type: UPDATE_USER,
                    payload: {...user}
                });
            }
        });
    }, [isAuthenticated])

    const api = (endpoint, options) => new Promise((resolve, reject) => {
        const { data, query, method = 'GET', handle = () => {} } = options;
        IdentityAPI.request((accessToken) => {
            Kirvis.request(method, endpoint, {
                data,
                query,
                accessToken
            }).then((res, status) => {
                resolve(res || { status: 500 });
                handle(res || { status: 500, data: {} }, status);
            });
        });
    });

    const login = async (email, password) => {
        const status = await IdentityAPI.auth({email, password});
        setAuthenticated(status);
        return status;
    }

    const logout = async () => {
        await IdentityAPI.deauth();
        setAuthenticated(false);
        navigate('/auth/login', { replace: true });
    }

    return (
        <IdentityContext.Provider value={{...state, isAuthenticated, api, login, logout, IdentityAPI }}>
            {children}
        </IdentityContext.Provider>
    )
}

export default IdentityContext;