import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';

const ConfirmationModal = ({
    title,
    body,
    onClose,
    onCancel = onClose,
    onConfirm,
    open,

    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel'
}) => {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            {open && (
                <>
                    <DialogTitle id="form-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={3}>
                            <DialogContentText>
                                <Typography variant="body2" component="span">
                                    {body}
                                </Typography>
                            </DialogContentText>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button 
                            sx={{ color: theme.palette.error.dark, border:'0px' }} 
                            onClick={onCancel}
                        >
                            {cancelButtonText}
                        </Button>
                        <Button 
                            variant="contained" 
                            size="small" 
                            onClick={() => {
                                onConfirm();
                                onClose();
                            }}
                        >
                            {confirmButtonText}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,

    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string
}

export default ConfirmationModal;