// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/overview';

export const LOCAL_SERVER = 'localhost:8081';
export const REMOTE_SERVER = 'api.ourodemi.com';
export const DEV_MODE = process.env.NODE_ENV === 'development';

export const IDENTITY_API = 'auth.ourodemi.com';

export const REGIONS = {
    do_ams3: { countryCode: 'EU', label: 'EU-AMS-1' }
}

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'dark', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
