import Projects from 'views/projects';
import Loader from 'ui-component/Loader';

import useProject from 'hooks/useProject';

const ProjectGuard = ({ children }) => {
    const { isLoading, project_id } = useProject();

    if ( isLoading ){
        return <Loader />;
    }

    if ( !project_id ){
        return <Projects />;
    }

    return children;
}

export default ProjectGuard;