import { useContext } from 'react';

import IdentityContext from "contexts/IdentityContext";


const useIdentity = () => {
    const context = useContext(IdentityContext);

    if ( !context ){
        throw new Error('useAPI must be used inside an Identity Provider');
    }

    return context;
}

export default useIdentity;