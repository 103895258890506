import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'ui-component/Loader';

import useIdentity from 'hooks/useIdentity';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useIdentity();

    const navigate = useNavigate();

    useEffect(() => {
        if ( isAuthenticated === false ){
            navigate('/auth/login', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    if ( isAuthenticated === null ){
        return <Loader />;
    }

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
