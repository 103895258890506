import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import Loadable from 'ui-component/Loadable';

const Landing = Loadable(lazy(() => import('views/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([{ 
        path: '/', 
        element: <Landing />
    }, LoginRoutes, MainRoutes]);
}
