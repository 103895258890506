import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ProjectGuard from 'utils/route-guard/ProjectGuard';

const Billing = Loadable(lazy(() => import('views/billing')));
const Overview = Loadable(lazy(() => import('views/dashboard/Overview')));
const Analytics = Loadable(lazy(() => import('views/dashboard/Analytics')));
const Users = Loadable(lazy(() => import('views/dashboard/Users')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Subscription = Loadable(lazy(() => import('views/subscription/Subscription')));
const UserProfile = Loadable(lazy(() => import('views/dashboard/Users/UserProfile')));

const Permissions = Loadable(lazy(() => import('views/access/Permissions')));
const ApiKeys = Loadable(lazy(() => import('views/access/APIKeys')));

const Settings = Loadable(lazy(() => import('views/settings/Settings')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/overview',
            element: (
                <ProjectGuard>
                    <Overview />
                </ProjectGuard>
            )
        },
        {
            path: '/billing',
            element: (
                <ProjectGuard>
                    <Billing />
                </ProjectGuard>
            )
        },
        {
            path: '/users',
            element: (
                <ProjectGuard>
                    <Users />
                </ProjectGuard>
            )
        },
        {
            path: '/analytics',
            element: (
                <ProjectGuard>
                    <Analytics />
                </ProjectGuard>
            )
        },
        {
            path: '/users/:user_id',
            element: (
                <ProjectGuard>
                    <UserProfile />
                </ProjectGuard>
            )
        },
        {
            path: '/permissions',
            element: (
                <ProjectGuard>
                    <Permissions />
                </ProjectGuard>
            )
        },
        {
            path: '/subscription',
            element: (
                <ProjectGuard>
                    <Subscription />
                </ProjectGuard>
            )
        },
        {
            path: '/apikeys',
            element: (
                <ProjectGuard>
                    <ApiKeys />
                </ProjectGuard>
            )
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/settings',
            element: (
                <ProjectGuard>
                    <Settings />
                </ProjectGuard>
            )
        }
    ]
};

export default MainRoutes;
