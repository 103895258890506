import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { Button, CardContent, CardActions, Divider, Grid, IconButton, Modal, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import CloseIcon from '@mui/icons-material/Close';

// generate random
function rand() {
    return Math.round(Math.random() * 20) - 10;
}

// modal position
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const Body = React.forwardRef(({ modalStyle, handleClose, hideCloseButton, title, children }, ref) => (
    <div ref={ref} tabIndex={-1}>
        <MainCard
            style={modalStyle}
            sx={{
                position: 'absolute',
                width: { xs: 280, lg: 450 },
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
            title={title}
            content={false}
            secondary={ hideCloseButton ? <></> :
                <IconButton onClick={handleClose} size="large">
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        >
            
            <CardContent>
                {children}
            </CardContent>
        </MainCard>
    </div>
));

Body.propTypes = {
    modalStyle: PropTypes.object,
    handleClose: PropTypes.func
};


const SimpleModal = ({
    open,
    title,
    onClose,
    children,
    hideCloseButton = false
}) => {
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal 
            open={open} 
            onClose={onClose}
            aria-labelledby={title}
        >
            <Body 
                modalStyle={modalStyle} 
                handleClose={onClose}
                title={title}
                hideCloseButton={hideCloseButton}
            >
                {children}
            </Body>
        </Modal>
    );
}

export default SimpleModal;