import { useEffect, useState, useContext } from 'react';

import { Grid, Typography, Button, Divider, CircularProgress } from '@mui/material';

import SimpleModal from 'ui-component/modals/SimpleModal';

import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import { IconIdBadge, IconX } from '@tabler/icons';

import IdentityContext from 'contexts/IdentityContext';

import ProjectCard from 'ui/cards/ProjectCard';

const SelectProjectModal = ({
    open = false,
    onSelect = () => {},
    onClose,

    onNewProjectButton
}) => {

    const { api } = useContext(IdentityContext);

    const [isLoading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if ( !isLoading ){
            return;
        }

        api('projects', {
            handle: ({ data }) => {
                setProjects(data);
                setLoading(false);
            }
        });
    }, [isLoading]);

    const renderProjects = () => projects.map((v,i) =>(
        <Grid marginBottom={1.5}>
            <ProjectCard 
                key={v.project_id}
                onUpdate={() => setLoading(true)}
                onLaunch={() => onSelect(v.project_id)}
                {...v}
            />
        </Grid>
    ));

    return (
        <SimpleModal 
            open={open}
            onClose={onClose}
            title={
                <Grid container alignItems='center'>
                    <IconIdBadge size={24} />
                    <Typography variant='h4' marginLeft={1}>
                        Select Identity Profile
                    </Typography>
                </Grid>
            }
            hideCloseButton={true}
        >
                <Grid>
                    <Grid container justifyContent='center' textAlign='center'>
                        {
                            /* eslint-disable-next-line */
                            isLoading ?
                            <CircularProgress />
                            :
                            projects.length > 0 ? renderProjects() :
                            <Typography variant='h5' margin={5}>
                                Looks like you have no active identity projects.
                                Start by creating one from below.
                            </Typography>
                        }
                    </Grid>

                    <br />
                    <Divider />
                    <br />
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Button 
                                onClick={onNewProjectButton}
                                variant="contained"
                                startIcon={<LayersTwoToneIcon />}
                            >
                                New Identity Project
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={onClose}
                                variant="contained" 
                                color="error" 
                                startIcon={<IconX />}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
        </SimpleModal>
    )
}

export default SelectProjectModal;