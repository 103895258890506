import { useState, createContext } from "react";

import PromptModal from 'ui/modals/PromptModal';
import ConfirmationModal from "ui/modals/ConfirmationModal";

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {

    const MODALS = {
        PROMPT: 'prompt',
        CONFIRMATION: 'confirmation'
    };

    const [modals, setModals] = useState({});

    const isModalOpen = (modal) => modals[modal] !== undefined;

    const closeModal = (modal) => {
        if ( modals[modal] ){
            let tmp = {...modals};
            delete(tmp[modal]);
            setModals(tmp);
        }
    }

    const launchModal = (modal, props = {}) => {
        let tmp = {...modals};
        tmp[modal] = props;
        setModals(tmp);
    }

    return (
        <ModalContext.Provider value={{ MODALS, launchModal }}>
            <PromptModal 
                open={isModalOpen(MODALS.PROMPT)}
                onClose={() => closeModal(MODALS.PROMPT)}
                {...modals[MODALS.PROMPT] || {}}
            />

            <ConfirmationModal 
                open={isModalOpen(MODALS.CONFIRMATION)}
                onClose={() => closeModal(MODALS.CONFIRMATION)}
                {...modals[MODALS.CONFIRMATION] || {}}
            />

            {children}
        </ModalContext.Provider>
    );
}

export default ModalContext;