// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

export const SELECTION = 'SELECTION';
export const API_TOKEN_RENEWED = 'API_TOKEN_RENEWED';
export const ACCESS_TOKEN_REFRESHED = 'ACCESS_TOKEN_REFRESHED';

export const RESET = 'RESET';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_STATS = 'UPDATE_PROJECT_STATS';
export const UPDATE_USER = 'UPDATE_USER';