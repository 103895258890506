import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import IdentityContext from 'contexts/IdentityContext';
import { DASHBOARD_PATH } from 'config';

import Loader from 'ui-component/Loader';


/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
    const { isAuthenticated } = useContext(IdentityContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated === true) {
            navigate(DASHBOARD_PATH, { replace: true });
        }
    }, [isAuthenticated, navigate]);

    if ( isAuthenticated === null ){
        return <Loader />;
    }

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
