import * as React from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';

import ReactCountryFlag from "react-country-flag"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius
        }
    }
}));

function RegionBlock({countryCode, regionName}){
    const FLAGS_CDN = "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/";

    return (
        <Grid container direction='column'>
            <Grid item>
                <ReactCountryFlag
                    svg
                    cdnSuffix="svg"
                    cdnUrl={FLAGS_CDN}
                    countryCode={countryCode} 
                />
            </Grid>

            <Grid item>
                <Typography variant='p'>
                    <b>{regionName}</b>
                </Typography>
            </Grid>
        </Grid>
    )
}

const RegionTabs = ({
    onChange
}) => {
    const theme = useTheme();

    const [formats, setFormats] = React.useState(() => ['bold', 'italic']);
    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };

    const [alignment, setAlignment] = React.useState('left');
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <Grid container direction='column'>
            <Grid container sx={{mb:1}}>
                <Typography variant='h5'>
                    <b>Datacenter Region</b>
                </Typography>
            </Grid>

            <Grid container>
                <Paper
                    elevation={0}
                    sx={{
                        display: 'flex',
                        border: (theme) => `0.5px solid ${theme.palette.primary.dark}`,
                        flexWrap: 'wrap'
                    }}
                >
                    <StyledToggleButtonGroup
                        size="small"
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                        color="primary"
                    >
                        <ToggleButton value="do_us_1" aria-label="left aligned" disabled>
                            <RegionBlock disabled countryCode='US' regionName='US-East-1' />
                        </ToggleButton>
                        <ToggleButton value="justify" aria-label="justified" disabled>
                            <RegionBlock countryCode='US' regionName='US-West-1' />
                        </ToggleButton>
                        <ToggleButton value="center" aria-label="centered">
                            <RegionBlock countryCode='EU' regionName='AMS-1' />
                        </ToggleButton>
                        <ToggleButton value="right" aria-label="right aligned" disabled>
                            <RegionBlock countryCode='DE' regionName='Germany-1' />
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Paper>
            </Grid>

            <Grid container sx={{mt:0.5}}>
                <Typography variant='p' fontSize={11} color={theme.palette.grey[400]}>
                     You will not be able to change datacenter regions later
                </Typography>
            </Grid>
        </Grid>
    );
}

export default RegionTabs;