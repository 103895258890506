import { useTheme } from '@mui/material/styles';

const Logo = () => {
    const theme = useTheme();

    return (
        <img 
            // src={theme.palette.mode === 'dark' ? logoDark : logo} 
            src='/assets/images/logo-text.png'
            alt="Ourodemi Identity" 
            width="170"
            style={{marginTop:5}}
        />
    );
};

export default Logo;
