import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Stack, 
    TextField, 
    Typography 
} from '@mui/material';

const PromptModal = ({
    title,
    body,
    onClose,
    onCancel = onClose,
    onConfirm,
    open,

    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',

    inputPlaceholder = '',
    inputLabel = '',
    inputType = 'text',
    defaultValue,

    matchRegex
}) => {
    const theme = useTheme();

    const [value, setValue] = useState(defaultValue);
    const [isValidated, setValidated] = useState(true);
    const [regExp, setRegExp] = useState(null);

    useEffect(() => {
        if ( !open ){
            return;
        }

        setValue(defaultValue);
    }, [open])

    // update regexp obj
    useEffect(() => {
        if ( !matchRegex ){
            setRegExp(null);
        }

        setRegExp(new RegExp(matchRegex));
    }, [matchRegex]);

    useEffect(() => {
        if ( !regExp ){
            return;
        }

        setValidated(regExp.test(value));
    }, [regExp, value]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            {open && (
                <>
                    <DialogTitle id="form-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={3}>
                            <DialogContentText>
                                <Typography variant="body2" component="span">
                                    {body}
                                </Typography>
                            </DialogContentText>
                            <TextField 
                                autoFocus 
                                size="small"
                                defaultValue={defaultValue} 
                                placeholder={inputPlaceholder}
                                onChange={({target}) => setValue(target.value)}
                                value={value}
                                label={inputLabel}
                                type={inputType} 
                                fullWidth 
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button sx={{ color: theme.palette.error.dark }} onClick={onCancel} color="secondary">
                            {cancelButtonText}
                        </Button>
                        <Button 
                            variant="contained" 
                            size="small" 
                            disabled={!isValidated}
                            onClick={() => {
                                onConfirm(value);
                                onClose();
                            }}
                        >
                            {confirmButtonText}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

PromptModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,

    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string
}

export default PromptModal;