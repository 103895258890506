import { 
    IconDashboard, 
    IconLockAccess,
    IconShield,
    IconUser,
    IconLink,
    IconUsers,
    IconColorSwatch,
    IconKey,
    IconSettings,
    IconCreditCard,
    IconChartBar
} from '@tabler/icons';

import TokenIcon from '@mui/icons-material/Token'

import useProject from 'hooks/useProject';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@mui/material';

const ProjectName = () => {
    const theme = useTheme();
    const { project_name } = useProject();

    return (
        <Typography variant='h5' color={theme.palette.grey[project_name ? 100 : 500]}>
            {project_name || 'No Profile Selected'}
        </Typography>
    )
}

const dashboard = {
    id: 'dashboard',
    title: <ProjectName />,
    type: 'group',
    children: [
        {
            id: 'overview',
            title: 'Overview',
            type: 'item',
            url: '/overview',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: IconUser,
            breadcrumbs: true
        },
        // {
        //     id: 'analytics',
        //     title: 'Analytics',
        //     type: 'item',
        //     url: '/analytics',
        //     icon: IconChartBar,
        //     breadcrumbs: true
        // },
        {
            id: 'webhooks',
            title: 'Webhooks',
            type: 'item',
            url: '/webhooks',
            icon: IconLink,
            breadcrumbs: false
        },
        {
            id: 'team',
            title: 'Project Team',
            type: 'item',
            url: '/team',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'branding',
            title: 'Identity Branding',
            type: 'item',
            url: '/branding',
            icon: IconColorSwatch,
            breadcrumbs: false
        },
        {
            id: 'access',
            title: 'Access Management',
            type: 'collapse',
            icon: IconLockAccess,
            breadcrumbs: true,
            children: [
                {
                    id: 'permissions',
                    title: 'Permissions & Groups',
                    type: 'item',
                    url: '/permissions',
                    icon: IconShield,
                    breadcrumbs: true
                },
                {
                    id: 'apikeys',
                    title: 'API Keys',
                    type: 'item',
                    url: '/apikeys',
                    icon: IconKey,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'settings',
            title: 'Identity Settings',
            type: 'item',
            url: '/settings',
            icon: IconSettings,
            // children: [
            //     {
            //         id: 'tokens',
            //         title: 'User Tokens',
            //         type: 'item',
            //         url: '/settings/tokens',
            //         icon: TokenIcon
            //     }
            // ]
        },
        {
            id: 'subscription',
            title: 'Subscription',
            type: 'item',
            url: '/subscription',
            icon: IconCreditCard,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
