import { Box, Avatar, Typography } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';

import { useTheme } from '@mui/material/styles';
import useProject from 'hooks/useProject';

const RegionSection = () => {
    const theme = useTheme();
    const { project_id, region_code } = useProject();

    if ( !project_id ){
        return (<div />);
    }

    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                    mr: 2
                },
                 width:150
            }}
        >
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.primary.dark
                    },
                    width: 150
                }}
            >
                <Typography variant='h5' fontSize='small' sx={{ color: theme.palette.grey[200] }}>
                    Region Status
                </Typography>
                <CircleIcon 
                    sx={{
                        fontSize: 12,
                        ml: 2,
                        color: theme.palette.success.dark
                    }}
                />
            </Avatar>
        </Box>
    );
}

export default RegionSection;