/* eslint-disable */
import { API_TOKEN_RENEWED, UPDATE_PROJECT, UPDATE_PROJECT_STATS } from "./actions";

export const initialState = {
    project_id: null,
    project_name: null,
    primary_domain: null,
    region_code: null,
    api_token: null,
    active_users: 0,
    total_users: 0,
    user_limit: 0,
    default_user_group: null,
    api_token_expiry: 0,
    apiVersion: 'v1'
};

function projectReducer(state = initialState, action){
    switch(action.type){
        case UPDATE_PROJECT:
            const { project_id, project_name, primary_domain, region_code } = action.payload;

            return {
                ...state,
                project_id,
                project_name,
                primary_domain,
                region_code
            };
        case API_TOKEN_RENEWED:
            const { api_token, api_token_expiry } = action.payload;

            return {
                ...state,
                api_token,
                api_token_expiry
            };
        case UPDATE_PROJECT_STATS:
            const { active_users, user_limit, total_users } = action.payload;

            return {
                ...state,
                active_users,
                user_limit,
                total_users
            };
        default:
            return { ...state };
    }

}

export default projectReducer;