import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Card, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography, Tooltip } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import DomainIcon from '@mui/icons-material/Domain';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useIdentity from 'hooks/useIdentity';
import useModal from 'hooks/useModal';
import useToast from 'hooks/useToast';

const ProjectCard = ({ project_id, region_code, primary_domain, project_name, onLaunch, onUpdate }) => {
    const theme = useTheme();
    const { MODALS, launchModal } = useModal();
    const { api } = useIdentity();
    const { toastSuccess } = useToast();

    const btnSX = {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.background.paper
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditName = () => {
        launchModal(MODALS.PROMPT, {
            title: 'Rename Identity Profile',
            inputPlaceholder: 'Enter a name for this profile',
            defaultValue: project_name,
            matchRegex: /^[A-Za-z _]{3,50}$/,

            onConfirm: (project_name) => {
                api('projects', {
                    method: 'UPDATE',
                    query: { project_name, project_id },
                    handle: onUpdate
                });
            }
        });
    }

    return (
        <Card
            sx={{
                p: 2,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                '&:hover': {
                    border: `1px solid${theme.palette.primary.main}`
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item>
                            <Avatar>
                                <DomainIcon />
                            </Avatar>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="h5"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                {project_name}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ mt: 0.5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                <LinkTwoToneIcon fontSize="inherit" sx={{ mr: 0.5, fontSize: '0.875rem', verticalAlign: 'text-top' }} />
                                {primary_domain}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" sx={{ mt: -0.75, mr: -0.75 }} onClick={handleClick}>
                                <MoreHorizOutlinedIcon
                                    fontSize="small"
                                    aria-controls="menu-friend-card"
                                    aria-haspopup="true"
                                    color="primary"
                                    sx={{ opacity: 0.6 }}
                                />
                            </IconButton>
                            <Menu
                                id="menu-friend-card"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={() => {
                                    navigator.clipboard.writeText(primary_domain);
                                    handleClose();
                                    toastSuccess("URL Copied to Clipboard");
                                }}>
                                    <ListItemIcon>
                                        <ContentCopyIcon fontSize="small" />
                                    </ListItemIcon>
                                    Copy URL
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <DeleteTwoToneIcon fontSize="small" />
                                    </ListItemIcon>
                                    Delete Project
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Tooltip title="Start viewing this project" placement="top">
                                <Button onClick={onLaunch} variant="outlined" color="secondary" fullWidth sx={btnSX}>
                                    Launch
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                            <Tooltip title="Edit project name" placement="top">
                                <Button variant="outlined" fullWidth sx={btnSX} onClick={handleEditName}>
                                    Edit
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

ProjectCard.propTypes = {
    primary_domain: PropTypes.string,
    region_code: PropTypes.string,
    project_name: PropTypes.string,
    onLaunch: PropTypes.func
};

export default ProjectCard;
