import { useState, useContext } from 'react';

import { Grid, Button } from '@mui/material';

import NewProjectModal from './NewProjectModal';
import SelectProjectModal from './SelectProjectModal';

import { IconIdBadge } from '@tabler/icons';

import ProjectContext from 'contexts/ProjectContext';

const Projects = () => {

    const { setProjectId } = useContext(ProjectContext);

    const [modal, setModal] = useState('selector');

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '70vh' }}
            >
                <Button 
                    onClick={() => setModal('selector')} 
                    variant='contained'
                    color='secondary'
                    size='large'
                    startIcon={<IconIdBadge />}
                >
                    Select Identity Profile
                </Button>
            </Grid>

            <NewProjectModal 
                open={modal === 'creator'}
                onClose={() => setModal(null)}
            />

            <SelectProjectModal
                open={modal === 'selector'}
                onClose={() => setModal(null)}
                onNewProjectButton={() => setModal('creator')}
                onSelect={(project_id) => {
                    setModal(null);
                    setProjectId(project_id);
                }}
            />
        </>
    );
}

export default Projects;