/* eslint-disable */
import { useContext} from 'react';

import ProjectContext from 'contexts/ProjectContext';

const useProject = () => {
    const context = useContext(ProjectContext);

    if ( !context ){
        throw new Error('useProject must be inside a Project Provider!');
    }

    return context;
}

export default useProject;