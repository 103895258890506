// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

import { ProjectProvider } from 'contexts/ProjectContext';
import { IdentityProvider } from 'contexts/IdentityContext';
import { ModalProvider } from 'contexts/ModalContext';
import { ToastProvider } from 'contexts/ToastContext';

const App = () => (
    <ThemeCustomization>
        <ToastProvider>
            <Locales>
                <NavigationScroll>
                    <ModalProvider>
                        <IdentityProvider>
                            <ProjectProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </ProjectProvider>
                        </IdentityProvider>
                    </ModalProvider>
                </NavigationScroll>
            </Locales>
        </ToastProvider>
    </ThemeCustomization>
);

export default App;
