/* eslint-disable */
import { createContext, useEffect, useReducer, useState, useRef } from 'react';
import { API_TOKEN_RENEWED, RESET, UPDATE_PROJECT, UPDATE_PROJECT_STATS } from 'store/actions';

import { useNavigate } from 'react-router-dom';

import useIdentity from 'hooks/useIdentity';

import projectReducer from 'store/projectReducer';

import axios from 'axios';

const initialState = {
    project_id: null,
    project_name: null,
    primary_domain: null,
    region_code: null,
    api_token: null,
    api_token_expiry: 0,
    apiVersion: 'v1'
};

const ProjectContext = createContext(null);

export const ProjectProvider = ({ children }) => {

    const { api, isAuthenticated } = useIdentity();

    const navigate = useNavigate();

    const [state, dispatch] = useReducer(projectReducer, initialState);
    const [isLoading, setLoading] = useState(false);
    const [project_id, setProjectId] = useState(null);
    const [apiVersion, setApiVersion] = useState('v1');

    const isRefreshing = useRef(false);

    // refresh api token
    const refreshToken = async () => {
        const { data = {} } = await api('projects/apitokens', {
            method: 'POST',
            data: { project_id }
        });

        const { api_token, api_token_expiry } = data;

        if ( api_token ){
            localStorage.setItem(project_id, JSON.stringify(data));
            dispatch({
                type: API_TOKEN_RENEWED,
                payload: {
                    api_token,
                    api_token_expiry
                }
            });

            return api_token;
        }
    };

    // check if api token has expired or exists
    // request a new token if not
    const validateApiToken = async () => new Promise(async (resolve,reject) => {
        if ( !project_id ){
            return resolve(false);
        }

        // wait for previous refresh request to complete
        if ( isRefreshing.current ){
            let intervalId = setInterval(() => {
                if ( !isRefreshing.current && state.api_token ){
                    clearInterval(intervalId);
                    resolve(state.api_token);
                }
            }, 500);
            return;
        }

        // request a new token if no current token is set
        if ( !state.api_token || (Date.now()/1000) > (state.api_token_expiry || 0) ){
            isRefreshing.current = true;
            const api_token = await refreshToken();
            isRefreshing.current = false;

            return resolve(api_token);
        }

        return resolve(state.api_token);
    });
    
    // loads api token for the current project from local storage
    const loadLocalToken = () => {
        try{
            const { api_token, api_token_expiry } = JSON.parse(
                localStorage.getItem(project_id) || '{}'
            ) || {};

            // update context state if token exists
            if ( api_token ){
                dispatch({
                    type: API_TOKEN_RENEWED,
                    payload: {
                        api_token,
                        api_token_expiry
                    }
                })
            }
        }catch(e){
            console.log(e);
        }
    }

    // clear current project context
    const clearProject = () => {
        localStorage.removeItem('currentProjectId');
        setProjectId(null);
        
        // flush current state
        dispatch({
            type: RESET,
            payload: initialState
        });

        // reload page
        navigate(0);
    }

    // Axios wrapper for making requests to the Identity API
    const projectApi = async (endpoint, options = {}) => new Promise(async (resolve, reject) => {
        if ( !state.primary_domain ){
            return resolve({}, 500);
        }

        const api_token = await validateApiToken();

        if ( !api_token ){
            return resolve({}, 500);
        }

        const { method = 'GET', data, params, handle = () => {} } = options;

        axios({
            url: `https://${state.primary_domain}/${apiVersion}/${endpoint}`,
            method,
            data,
            params,
            headers: {
                'x-api-token': api_token
            }
        }).then((res, status) => {
            return resolve(res?.data || {status:500, data: {}});
        }).catch((res, status) => {
            return resolve(res?.data || {status:500, data: {}});
        });
    });

    // loads project and api token
    useEffect(() => {
        if ( !project_id || !isAuthenticated ){
            return;
        }

        const init = async () => {
            setLoading(true);
            const { data = {} } = await api('projects', { query: { project_id } });
            if ( data.project_id ){
                localStorage.setItem('currentProjectId', project_id);
                dispatch({
                    type: UPDATE_PROJECT,
                    payload: {...data}
                });
                loadLocalToken();
                setLoading(false);
                return;
            }

            setProjectId(null);
        }

        init();
    }, [project_id, isAuthenticated]);

    /* 
        Load previously saved / selected project from 
        local storage once authentication state has
        been set to true
    */
    useEffect(() => {
        if ( !isAuthenticated ){
            return;
        }

        setProjectId(localStorage.getItem('currentProjectId') || null);
    }, [isAuthenticated]);

    // load some important project information
    useEffect(() => {
        if ( !state.project_id ){
            return;
        }

        projectApi('stats').then(({data}) => {
            const { user_limit, active_users, total_users } = data;

            dispatch({
                type: UPDATE_PROJECT_STATS,
                payload: {
                    user_limit,
                    active_users,
                    total_users
                }
            });
        });
    }, [state.project_id]);

    return (
        <ProjectContext.Provider value={{
            ...state, setProjectId, isLoading, setApiVersion, clearProject, projectApi}}>
            {children}
        </ProjectContext.Provider>
    )
}

export default ProjectContext;