import { useEffect, useState } from 'react';

import { Grid, Typography, Button, Divider, CircularProgress, TextField, FormControl, InputLabel } from '@mui/material';

import SimpleModal from 'ui-component/modals/SimpleModal';

import { IconIdBadge, IconX } from '@tabler/icons';

import RegionTabs from 'ui/misc/RegionTabs';

const NewProjectModal = ({
    open,
    onClose,
    onCreate
}) => {

    const [project_name, setProjectName] = useState('');
    const [region_code, setRegionCode] = useState(null);

    const [isValidated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {

    }, []);

    return (
        <SimpleModal 
            open={open}
            onClose={onClose}
            title={
                <Grid container alignItems='center'>
                    <IconIdBadge size={24} />
                    <Typography variant='h4' marginLeft={1}>
                        New Identity Profile
                    </Typography>
                </Grid>
            }
        >
            <Grid>
                <Grid container justifyContent='center' textAlign='center'>
                    {
                        /* eslint-disable-next-line */
                        isLoading ?
                        <CircularProgress />
                        :
                        <Grid container>
                            <TextField 
                                fullWidth
                                placeholder='Profile Name'
                                label='Profile Name'
                                sx={{ mb: 2 }}
                            />

                            <TextField 
                                fullWidth
                                label='Company or Organization'
                                placeholder='Company or Organization'
                                sx={{ mb: 2 }}
                            />
                            
                            <RegionTabs />
                        </Grid>
                    }
                </Grid>

                <br />
                <Divider />
                <br />
                <Grid fullWidth>
                    <Button
                        onClick={onClose}
                        fullWidth
                        disabled={!isValidated}
                        variant="contained"
                        color="secondary"
                    >
                        Create Identity Profile
                    </Button>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default NewProjectModal;